/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

function BodyTopContent({ offerData, pdfLang }) {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    flex: {
      display: 'flex',
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      flexWrap: 'wrap',
      fontSize: '10px',
    },
    colom8: {
      width: '60%',
      margin: '5px 0',
    },
    colom4: {
      width: '40%',
      margin: '5px 0',
    },
    title: {
      textAlign: 'center',
      display: 'block',
      margin: '5px 0',
    },
    boldText: {
      fontStyle: 'bold',
      fontSize: '12px',
    },
  });
  return (
    <View style={{
      direction: `${pdfLang === '1' ? 'ltr' : 'rtl'}`,
    }}
    >

      <Text style={styles.title}>{ pdfLang === '1' ? ' Price Offer' : 'عرض أسعار '}</Text>
      <View style={styles.flex}>
        <View style={styles.colom8}>
          <View style={styles.flex}>
            <Text style={styles.boldText}>{ pdfLang === '1' ? 'Company:' : ' : شركة '}</Text>
            <Text className="px-2 mb-0">
              {' '}
              {offerData?.ClientNameInPO}
            </Text>
          </View>
        </View>
        <View style={styles.colom4}>
          <View style={styles.flex}>
            <Text style={styles.boldText}>
              {' '}
              { pdfLang === '1' ? 'Attention:' : ' : عنايه'}
            </Text>
            <Text className="px-2 mb-0">
              {' '}
              {offerData?.PriceOfferSentTo}
            </Text>
          </View>
        </View>
        <View style={styles.colom8}>
          <View style={styles.flex}>
            <Text style={styles.boldText}>
              {' '}
              { pdfLang === '1' ? 'Date:' : ' : التاريخ'}
            </Text>
            <Text className="px-2 mb-0">
              {' '}
              {offerData?.CreationDate?.substring(0, 10)}
            </Text>
          </View>
        </View>
        <View style={styles.colom4}>
          <View style={styles.flex}>
            <Text style={styles.boldText}>{ pdfLang === '1' ? 'Price offer:' : ' : عرض سعر'}</Text>
            <Text className="px-2 mb-0">
              #
              {offerData?.SerialNumber}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default BodyTopContent;
