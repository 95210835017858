/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc';
const switchNumbersDirectionInWord = (word) => {
  // Variables to store the numbers before and after switching
  let numbersBeforeSwitch = '';
  let switchedNumbers = '';

  // Flag to check if the current character is part of a number
  let inNumber = false;

  // Iterate through each character in the word
  for (const char of word) {
    // Check if the character is a numeral
    if (char >= '0' && char <= '9') {
      // If not in a number, start a new number
      if (!inNumber) {
        numbersBeforeSwitch = char;
        inNumber = true;
      } else {
        // Add the character to the current number
        numbersBeforeSwitch += char;
      }
    } else {
      // If the character is not a numeral, end the current number
      if (inNumber) {
        // Switch the direction of the numeral and add it to the switched numbers
        const switchedNumeral = numbersBeforeSwitch.split('').reverse().join('');
        switchedNumbers += switchedNumeral;
        numbersBeforeSwitch = '';
        inNumber = false;
      }
      // Add the character to the switched numbers as is
      switchedNumbers += char;
    }
  }

  // If there are remaining numbers, switch their direction
  if (inNumber) {
    const switchedNumeral = numbersBeforeSwitch.split('').reverse().join('');
    switchedNumbers += switchedNumeral;
  }

  // Combine the word before and after switching
  const switchedWord = switchedNumbers;

  return switchedWord;
};
function PdfTableRow({ item, IssueProduct, pdfLang }) {
  const styles = StyleSheet.create({
    row: {
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      borderBottomColor: '#bff0fd',
      borderBottomWidth: 1,
      alignItems: 'center',
      // height: 30,
      // fontStyle: 'bold',
      fontSize: '11px',
      borderLeft: '2px solid #ba2928',
      borderRight: '2px solid #ba2928',
      borderBottom: '2px dashed #ba2928',
      margin: '-1 0',
    },
    description: {
      width: '60%',
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      padding: '5px',
      display: 'flex',
      textAlign: pdfLang === '1' ? 'left' : 'right',
    },
    qty: {
      width: '20%',
      padding: '5px',
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      display: 'flex',
      textAlign: pdfLang === '1' ? 'left' : 'right',
    },
    rate: {
      width: '20%',
      flexDirection: pdfLang === '1' ? 'row' : 'row-reverse',
      padding: '5px',
      borderRight: '2px solid #ba2928',
      borderLeft: '2px solid #ba2928',
      display: 'flex',
    },
  });
  const productNamePO = item.ProductNamePO;
  const productNameAr = switchNumbersDirectionInWord(item?.ProductSystem9Json?.ProductNameAr);
  return (

    <View style={styles.row} key={item?.Id}>
      <View style={styles.description}>
        <Text>
          {' '}
          {IssueProduct === '1' ? productNamePO : IssueProduct !== '1' && pdfLang === '1' ? item?.ProductSystem9Json?.ProductNameEn : productNameAr }
        </Text>
      </View>

      <View style={styles.rate}>
        <Text>
          {item?.SellPriceWithNoTax}
        </Text>
      </View>
      <View style={styles.qty}>
        <Text>
          {item.Quantity}
        </Text>
      </View>
    </View>
  );
}

export default PdfTableRow;
