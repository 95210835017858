/* eslint-disable react/react-in-jsx-scope */
import {
  Page, Image, Font, View, Document, StyleSheet, PDFDownloadLink,
} from '@react-pdf/renderer';
// import headerEn from '../../../assets/images/Header/2.png';
import footerAr from '../../../assets/images/Header/arFooter.png';
import enFooter from '../../../assets/images/Header/enFooter.png';
import arHeader from '../../../assets/images/Header/arHeader.png';
import enHeader from '../../../assets/images/Header/enHeader.png';
import arYasser from '../../../assets/images/Header/arYasser.png';
import enYasser from '../../../assets/images/Header/enYasser.png';
import PdfTableHeader from './PdfTableHeader';
import PdfTableRow from './PdfTableRow';
import BodyBottomContent from './BodyBottomContent';
import BodyTopContent from './BodyTopContent';
import AlmaraiRegular from '../../../assets/fonts/Almarai/Almarai-Regular.ttf';
import AlmaraiBold from '../../../assets/fonts/Almarai/Almarai-Bold.ttf';

function PdfPage({
  offerData, pdfLang, IssueProduct, issueFrom,
}) {
  Font.register({
    family: 'Almarai',
    fonts: [
      { src: AlmaraiBold, fontStyle: 'bold' },
      { src: AlmaraiRegular, fontStyle: 'regular' },
    ],

  });
  // console.log(pdfLang, offerData, 'fffffffffffff');
  return (

    <Document>
      <Page wrap>
        <View style={{ flex: 1 }}>
          {/* header */}
          <View fixed className="pdf-header">
            <Image
              src={pdfLang === '1' && issueFrom === '1' ? enYasser
                : pdfLang !== '1' && issueFrom === '1' ? arYasser
                  : pdfLang === '1' && issueFrom !== '1' ? enHeader
                    : arHeader}
              alt=""
              className="img-fluid w-100"
            />
          </View>
          {/* body */}
          <View style={{
            flex: 1,
            padding: '10px',
            fontFamily: 'Almarai',
            fontStyle: 'regular',
            direction: pdfLang === '0' ? 'rtl' : 'ltr', // Set RTL for Arabic
            textAlign: pdfLang === '0' ? 'right' : 'left',
          }}
          >
            <BodyTopContent offerData={offerData} pdfLang={pdfLang} />
            <PdfTableHeader pdfLang={pdfLang} />
            {offerData?.PriceOfferItems?.map((el) => (
              <PdfTableRow IssueProduct={IssueProduct} pdfLang={pdfLang} item={el} />
            ))}
            <BodyBottomContent offerData={offerData} pdfLang={pdfLang} issueFrom={issueFrom} />
          </View>
          {/* footer */}
          <View fixed className="pdf-footer">
            <Image src={pdfLang === '1' ? enFooter : footerAr} alt="" className="img-fluid w-100" />
          </View>
        </View>
      </Page>

    </Document>

  );
}
export default PdfPage;
