/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from "react";
import {
  Label,
  Form,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Table,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { toast } from "react-hot-toast";
import { useQuery, useQueryClient } from "react-query";
import AuthApiEndpoints from "../../ymc-sdk/services/auth.api";
import Loading from "../../components/Loading";
import Api from "../../ymc-sdk/services";
import PriceOfferSearchRoleSetting from "./priceOfferSearchRoleSetting";
import ClientsApiEndpoints from "../../ymc-sdk/services/client.api";

function RoleActionsSetting() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { roleId } = useParams();
  const UserId = localStorage.getItem("UserId");
  const [isloading, setLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [roleActions, setRoleActions] = useState(null);
  const [originalRoleActions, setOriginalRoleActions] = useState(null);
  const navigate = useNavigate();
  const pageActions = useSelector((state) => state.auth?.userData?.PageActions);

  const { data: actions } = useQuery(["actions"], Api.roleActions.getActions, {
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // 15 mins
    select: (data) => {
      // console.log("11111111");
      return data;
    },
  });
  const { data: allPages, isLoading } = useQuery(
    ["allPages"],
    Api.roleActions.getAllPages,
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        // console.log("2222222222222222");
        return data;
      },
    }
  );
  const { data: clients9 } = useQuery(
    ["Clients 9"],
    () => ClientsApiEndpoints.getClientsSystem9(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  // get clients system YMC
  const { data: clientsYMC } = useQuery(
    ["Clients YMC"],
    () => ClientsApiEndpoints.getClientsYMC(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  const { data: adminUsers } = useQuery(
    ["admin users"],
    () => AuthApiEndpoints.getSystemUsers(),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      select: (data) => {
        return data;
      },
    }
  );
  useEffect(() => {
    // console.log(adminUsers);
    setLoading(true);
    Api.roleActions
      .getRoleData(roleId)
      .then((res) => {
        setRole(res.Data);
        return Api.roleActions.getRoleActions({ RoleId: roleId });
      })
      .then((res) => {
        setOriginalRoleActions(res.Data);
        setRoleActions(res.Data);
      })
      .catch((err) => {
        // console.log(err);
        toast.error(t("shared.sorrySomethingWrong"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const handleCheckboxChange = (pageId, actionId) => {
    // Check if the roleActions array already contains an object with the same pageId and actionId
    const existingIndex = roleActions.findIndex(
      (ra) => ra.PageId === pageId && ra.ActionId === actionId
    );
    // console.log(existingIndex);
    if (existingIndex !== -1) {
      // If it exists, remove it from the array
      const updatedRoleActions = [...roleActions];
      updatedRoleActions.splice(existingIndex, 1);
      setRoleActions(updatedRoleActions);
    } else {
      // If it doesn't exist, add it to the array
      const updatedRoleActions = [
        ...roleActions,
        { PageId: pageId, ActionId: actionId, RoleId: roleId },
      ];
      setRoleActions(updatedRoleActions);
    }
  };
  const handleEditButtonClick = () => {
    // Calculate differences between current roleActions and originalRoleActions
    const NewObjectsRolesActions = roleActions.filter(
      (ra) =>
        !originalRoleActions.some(
          (originalRa) =>
            ra.PageId === originalRa.PageId &&
            ra.ActionId === originalRa.ActionId
        )
    );

    const DeletedObjectsRolesActions = originalRoleActions.filter(
      (originalRa) =>
        !roleActions.some(
          (ra) =>
            ra.PageId === originalRa.PageId &&
            ra.ActionId === originalRa.ActionId
        )
    );
    const data = {
      NewObjectsRolesActions,
      DeletedObjectsRolesActions,
    };
    setLoading(true);
    // console.log(data);
    Api.roleActions
      .updateRoleActions({ UserId }, data)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        toast.success(t("shared.editedSuccessfully"));
        navigate("/userSettings");
      })
      .catch((err) => {
        // console.log(err);
        toast.error(t("shared.sorrySomethingWrong"));
      });
  };

  return (
    <div className="auth-modal body-style mt-3 px-5 py-5">
      <Helmet>
        <title>{`YMC Group | ${t("userSettings.editRoleActions")} | ${
          role?.Name || " "
        }`}</title>
      </Helmet>
      <div className="text-center mb-4">
        <h2 className="">{role?.Name}</h2>
      </div>
      {pageActions?.includes("editRoleActions_ListView") && (
        <>
          <Table striped bordered hover className="mt-3 table-responsive mb-0">
            <thead className="text-white" style={{ backgroundColor: "#333" }}>
              <tr>
                <th
                  className="bg-dark text-white"
                  style={{ verticalAlign: "middle" }}
                >
                  {t("userSettings.pageName")}
                </th>
                {actions?.map((action) => (
                  <th
                    className="bg-dark text-white"
                    style={{ verticalAlign: "middle" }}
                    key={action.Id}
                  >
                    {i18n.language === "en" ? action.NameEn : action.NameAr}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {allPages?.map((page) => (
                <tr key={page.Id}>
                  <td>{i18n.language === "en" ? page.NameEn : page.NameAr}</td>
                  {actions?.map((action) => (
                    <td key={action.Id}>
                      <input
                        type="checkbox"
                        checked={roleActions?.some(
                          (ra) =>
                            ra.PageId === page.Id && ra.ActionId === action.Id
                        )}
                        onChange={() =>
                          handleCheckboxChange(page.Id, action.Id)
                        }
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          {/* <PriceOfferSearchRoleSetting clientsYMC={clientsYMC} clients9={clients9} adminUsers={adminUsers} /> */}
          {pageActions?.includes("editRoleActions_Update") && (
            <Button
              className="main-button primary d-block btn m-auto mt-5"
              disabled={!pageActions?.includes("editRoleActions_Update")}
              onClick={handleEditButtonClick}
            >
              {t("userSettings.send")}
            </Button>
          )}
        </>
      )}

      {isloading && <Loading />}
      {isLoading && <Loading />}
    </div>
  );
}

export default RoleActionsSetting;
